import * as type from '../type';
import * as queue from '../../api/queue';

const state = {}

const actions = {
    queueGetPayPlanListByCompanyId({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getPayPlanListByCompanyId(
                payload.employer_id,
                payload.state,
                payload.company_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueGetErrorList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getErrorList(
                payload.employer_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueGetErrorExcel({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getErrorExcel(
                payload.employer_id,
                payload.queue_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueGetList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getList(
                payload.employer_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueGetTempSaveList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getTempSaveList(
                payload.employer_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueGetDoneList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getDoneList(
                payload.employer_id,
                payload.page_index,
                payload.page_size,
                payload.search_content,
                payload.start_time,
                payload.end_time,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueGetDoingList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getDoingList(
                payload.employer_id,
                payload.page_index,
                payload.page_size,
                payload.search_content,
                payload.start_time,
                payload.end_time,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueGetDetail({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getDetail(
                payload.employer_id,
                payload.queue_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueGetServiceAmount({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getServiceAmount(
                payload.employer_id,
                payload.salary_amount,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueCreate({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.create(
                payload.company_contact_id,
                payload.employer_id,
                payload.info_arr,
                payload.more,
                payload.pay_time,
                payload.pwd,
                payload.temp_save,
                payload.type,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueCheckPwd({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.checkPwd(
                payload.employer_id,
                payload.pwd,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueRemoveQueue({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.removeQueue(
                payload.employer_id,
                payload.queue_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueRePay({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.rePay(
                payload.employer_id,
                payload.queue_id,
                payload.pwd
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueGetPdf({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getPdf(
                payload.employer_id,
                payload.queue_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueDelError({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.delError(
                payload.employer_id,
                payload.error_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve();
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueGetPayPlanListByEmployerId({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getPayPlanListByEmployerId(
                payload.employer_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueGetSummary({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getSummary(
                payload.employer_id,
                payload.pay_plan_arr
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        });
    },
    queueGetErrorPayList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getErrorPayList(
                payload.employer_id,
                payload.error_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        });
    },
    queueCheckEmployee({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.checkEmployee(
                payload.employer_id,
                payload.client_name,
                payload.client_phone,
                payload.client_idnum,
                payload.position,
                payload.bank_name,
                payload.bank_num,
                payload.bank_location,
                payload.amount,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        });
    },
    queueGetDetailByErrorId({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getDetailByErrorId(
                payload.employer_id,
                payload.error_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        });
    },
    queueRePayByManual({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.rePayByManual(
                payload.error_id,
                payload.employer_id,
                payload.project_id,
                payload.pay_item_arr,
                payload.company_id,
                payload.company_name,
                payload.company_contact_id,
                payload.name,
                payload.type,
                payload.pay_time,
                payload.more,
                payload.pwd,
                payload.temp_save
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueGetErrorDetail({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getErrorDetail(
                payload.error_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        });
    },
    queueSyncLG({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.syncLG(
                payload.old_pay_item_arr,
                payload.new_pay_item_arr,
                payload.error_id,
                payload.company_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        });
    },
    queueCreateSBYExcel({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.createSBYExcel(
                payload.queue_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        });
    },
    queueCreateSBYZip({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.createSBYZip(
                payload.queue_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        });
    },
    queueCreateSBYPersonZip({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.createSBYPersonZip(
                payload.queue_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        });
    },
    queueSendSBYZip({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.sendSBYZip(
                payload.queue_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        });
    },
    queueSendSBYExcel({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.sendSBYExcel(
                payload.queue_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        });
    },
    queueGetSBYFileUrl({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.getSBYFileUrl(
                payload.queue_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    queueSendSBYBankPdf({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            queue.sendSBYBankPdf(
                payload.queue_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    }
}

const mutations = {};

export default {
    state,
    actions,
    mutations
}