import api from './config'

export function getPayPlanListByCompanyId(
  employer_id,
  state,
  company_id
) {
  return api.post('queue/getPayPlanListByCompanyId', {
    employer_id,
    state,
    company_id
  })
}

export function getErrorList(
  employer_id
) {
  return api.post('queue/getErrorList', {
    employer_id
  })
}

export function getErrorExcel(
  employer_id,
  queue_id
) {
  return api.post('queue/getErrorExcel', {
    employer_id,
    queue_id
  })
}

export function getList(
  employer_id,
) {
  return api.post('queue/getList', {
    employer_id,
  })
}

export function getDoneList(
  employer_id,
  page_index,
  page_size,
  search_content,
  start_time,
  end_time,
) {
  return api.post('queue/getDoneList', {
    employer_id,
    page_index,
    page_size,
    search_content,
    start_time,
    end_time,
  })
}

export function getDoingList(
  employer_id,
  page_index,
  page_size,
  search_content,
  start_time,
  end_time,
) {
  return api.post('queue/getDoingList', {
    employer_id,
    page_index,
    page_size,
    search_content,
    start_time,
    end_time,
  })
}

export function getTempSaveList(
  employer_id,
) {
  return api.post('queue/getTempSaveList', {
    employer_id,
  })
}

export function getDetail(
  employer_id,
  queue_id
) {
  return api.post('queue/getDetail', {
    employer_id,
    queue_id
  })
}

export function getServiceAmount(
  employer_id,
  salary_amount
) {
  return api.post('queue/getServiceAmount', {
    employer_id,
    salary_amount
  })
}

export function create(
  company_contact_id,
  employer_id,
  info_arr,
  more,
  pay_time,
  pwd,
  temp_save,
  type,
) {
  return api.post('queue/multiCreate', {
    company_contact_id,
    employer_id,
    info_arr,
    more,
    pay_time,
    pwd,
    temp_save,
    type,
  })
}

export function checkPwd(
  employer_id,
  pwd
) {
  return api.post('queue/checkPwd', {
    employer_id,
    pwd
  })
}

export function removeQueue(
  employer_id,
  queue_id
) {
  return api.post('queue/removeQueue', {
    employer_id,
    queue_id
  })
}

export function rePay(
  employer_id,
  queue_id,
  pwd,
) {
  return api.post('queue/rePay', {
    employer_id,
    queue_id,
    pwd,
  })
}

export function getPdf(
  employer_id,
  queue_id
) {
  return api.post('queue/getPdf', {
    employer_id,
    queue_id
  })
}

export function delError(
  employer_id,
  error_id
) {
  return api.post('queue/delError', {
    employer_id,
    error_id
  })
}

export function getPayPlanListByEmployerId(
  employer_id
) {
  return api.post('queue/getPayplanListByEmployerId', {
    employer_id
  });
}

export function getSummary(
  employer_id,
  pay_plan_arr
) {
  return api.post('queue/getSummary', {
    employer_id,
    pay_plan_arr
  });
}

export function getErrorPayList(
  employer_id,
  error_id,
) {
  return api.post('queue/getErrorPayList', {
    employer_id,
    error_id,
  })
}

export function checkEmployee(
  employer_id,
  client_name,
  client_phone,
  client_idnum,
  position,
  bank_name,
  bank_num,
  bank_location,
  amount,
) {
  return api.post('queue/checkEmployee', {
    employer_id,
    client_name,
    client_phone,
    client_idnum,
    position,
    bank_name,
    bank_num,
    bank_location,
    amount,
  })
}

export function getDetailByErrorId(
  employer_id,
  error_id,
) {
  return api.post('queue/getDetailByErrorId', {
    employer_id,
    error_id,
  })
}

export function rePayByManual(
  error_id,
  employer_id,
  project_id,
  pay_item_arr,
  company_id,
  company_name,
  company_contact_id,
  name,
  type,
  pay_time,
  more,
  pwd,
  temp_save
) {
  return api.post('queue/rePayByManual', {
    error_id,
    employer_id,
    project_id,
    pay_item_arr,
    company_id,
    company_name,
    company_contact_id,
    name,
    type,
    pay_time,
    more,
    pwd,
    temp_save
  })
}

export function getErrorDetail(
  error_id
) {
  return api.post('queue/getErrorDetail', {
    error_id
  });
}

export function syncLG(
  old_pay_item_arr,
  new_pay_item_arr,
  error_id,
  company_id,
) {
  return api.post('queue/syncLG', {
    old_pay_item_arr,
    new_pay_item_arr,
    error_id,
    company_id,
  })
}

export function createSBYExcel(
  queue_id
) {
  return api.post('queue/createSBYExcel', {
    queue_id
  })
}

export function createSBYZip(
  queue_id
) {
  return api.post('queue/createSBYZip', {
    queue_id
  })
}

export function createSBYPersonZip(
  queue_id
) {
  return api.post('queue/createSBYPersonZip', {
    queue_id
  })
}

export function sendSBYZip(
  queue_id
) {
  return api.post('queue/sendSBYZip', {
    queue_id
  })
}

export function sendSBYExcel(
  queue_id
) {
  return api.post('queue/sendSBYExcel', {
    queue_id
  })
}

export function getSBYFileUrl(
  queue_id
) {
  return api.post('queue/getSBYFileUrl', {
    queue_id
  })
}

export function sendSBYBankPdf(
  queue_id
) {
  return api.post('queue/sendSBYBankPdf', {
    queue_id
  })
}
